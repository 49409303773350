import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { userGuard } from './api/guards/user.guard';
import { permissionGuard } from './api/guards/permissions.guard';
import { Permission } from './api/models/permission';
import { AccessLevel, FunctionalityName } from './api/models/functionality';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./ui/pages/login/login.component').then(
        (mod) => mod.LoginComponent
      ),
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./ui/pages/dashboard/dashboard.component').then(
        (mod) => mod.DashboardComponent
      ),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
          level: AccessLevel.READ,
        },
      ],
    },
  },
  {
    path: 'participant',
    loadComponent: () =>
      import('./ui/pages/participant/participant.component').then(
        (mod) => mod.ParticipantComponent
      ),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.VIEW_FULL_PARTICIPANT,
          level: AccessLevel.READ,
        },
      ],
    },
  },
  {
    path: 'pre-winners/:id',
    loadComponent: () =>
      import('./ui/pages/pre-winners/pre-winners.component').then(
        (mod) => mod.PreWinnersComponent
      ),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
          level: AccessLevel.READ,
        },
      ],
    },
  },
  {
    path: 'user-administration',
    loadComponent: () =>
      import(
        './ui/pages/user-administration/user-administration.component'
      ).then((mod) => mod.UserAdministrationComponent),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.GRANT_PORTAL_ACCESS,
          level: AccessLevel.CREATE,
        },
        {
          identifier: FunctionalityName.LIST_ALL_USERS,
          level: AccessLevel.READ,
        },
      ],
    },
  },
  {
    path: 'management',
    loadComponent: () =>
      import(
        './ui/pages/management/management.component'
      ).then((mod) => mod.ManagementComponent),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
          level: AccessLevel.READ,
        },
        {
          identifier: FunctionalityName.GRANT_VIEWING_PROMO_ACCESS,
          level: AccessLevel.READ,
        },
      ],
    },
  },
  {
    path: 'management/:id',
    loadComponent: () =>
      import(
        './ui/pages/management-dashboard/management-dashboard.component'
      ).then((mod) => mod.ManagementDashboardComponent),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
          level: AccessLevel.READ,
        },
        {
          identifier: FunctionalityName.LIST_ALL_USERS,
          level: AccessLevel.READ,
        },
      ],
    },
  },
  {
    path: 'management/:id/users',
    loadComponent: () =>
      import(
        './ui/pages/management-users/management-users.component'
      ).then((mod) => mod.ManagementUsersComponent),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
          level: AccessLevel.READ,
        },
        {
          identifier: FunctionalityName.LIST_ALL_USERS,
          level: AccessLevel.READ,
        },
        {
          identifier: FunctionalityName.GRANT_VIEWING_PROMO_ACCESS,
          level: AccessLevel.UPDATE,
        },
        {
          identifier: FunctionalityName.REMOVE_VIEWING_PROMO_ACCESS,
          level: AccessLevel.UPDATE,
        },
      ],
    },
  },
  {
    path: 'management/:promotionId/prizes',
    loadComponent: () =>
      import('./ui/pages/management-prizes/management-prizes.component').then(
        (mod) => mod.ManagementPrizesComponent
      ),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.VIEW_ALL_PRIZES,
          level: AccessLevel.READ,
        },
        {
          identifier: FunctionalityName.UPDATE_PRIZES_DATA,
          level: AccessLevel.UPDATE,
        },
      ],
    },
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import('./ui/pages/access-denied/access-denied.component').then(
        (mod) => mod.AccessDeniedComponent
      ),
    canActivate: [MsalGuard],
  },
];
