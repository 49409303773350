import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
} from '@angular/router';
import { UserService } from '../services/user.service';

export const userGuard: CanActivateFn = (
) => {
  const router = inject(Router);
  const userService = inject(UserService);
  const userCache = userService.getCache();

  if (userCache === null) {
    router.navigate(['/access-denied']);
  }

  return true;
};
