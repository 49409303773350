import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { Permission } from '../models/permission';

export const permissionGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const userService = inject(UserService);

  const routePermissionsRequired: Permission[] = route.data['permissions'];

  const userHasRequiredPermissions = routePermissionsRequired.every(
    (permission) => {
      return userService.userHasPermission(
        permission.identifier,
        permission.level
      );
    }
  );

  if (!userHasRequiredPermissions) {
    router.navigate(['/access-denied']);
  }

  return true;
};
