<div class="main">
  <div class="header">
    <app-header></app-header>
  </div>

  <div class="content-wrapper" [ngClass]="{ 'content-wrapper-login': isLogin }">
    <app-menu [items]="menuItems"></app-menu>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer [isLogin]="isLogin"></app-footer>
</div>

<app-loader></app-loader>
