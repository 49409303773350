import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { UserService } from '../api/services/user.service';
import { AuthService } from '../ui/services/auth.service';
import { EMPTY, Subject, catchError, filter, takeUntil } from 'rxjs';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { FunctionalityService } from '../api/services/functionality.service';

export class MsalListenersConfig {
  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private userService: UserService,
    private functionalityService: FunctionalityService,
    private window: Window
  ) {}

  private readonly _destroying$ = new Subject<void>();

  initMsal() {
    this.msalService
      .handleRedirectObservable()
      .pipe(
        catchError(() => {
          this.msalService.instance.clearCache();
          return EMPTY;
        })
      )
      .subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.LOGIN_FAILURE
        )
      )
      .subscribe(({ eventType }) => {
        if (eventType === EventType.LOGIN_FAILURE) {
          this.msalService.instance.clearCache();
        } else {
          this.checkLoginStatusAndRedirect();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  checkLoginStatusAndRedirect() {
    if (this.authService.isAuthenticated()) {
      this.userService
        .get()
        .subscribe({
          next: () => {
            this.functionalityService.list()
            .subscribe(() => {
              this.window.location.pathname = '/dashboard';
            });
          },
          error: () => {
            this.window.location.pathname = '/';
          },
        });

      return;
    }

    this.window.location.pathname = '/';
  }

  destroyMsal() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
